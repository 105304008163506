import styled from 'styled-components'
import { defaultSectionTheme } from '@/constants'
import ArrowRight from '../../../../public/images/icons/arrow-right.svg'

const MAX_SLIDES = 4
const DESIGN_MAX_SLIDES = 3

export const Wrapper = styled.div<{ length: number }>`
  overflow: visible;
  padding-bottom: ${props => props.length > 1 ? 15 : 0}px;

  // style for issue when using variableWidth
  .slick-track {
    display: flex;
  }

  // padding for when dots show up
  & > .slick-slider {
    padding-bottom: ${props => props.length > 1 ? 30 : 0}px;
  }

  // For showing the next slides
  .slick-list {
    overflow: visible;
    margin: 0 -10px;

    .slick-slide {
      height: auto;
      width: auto;

      & > div {
        display: flex;
        height: 100%;
        padding: 0 15px;
        & > div {
          margin: 0 auto;
          width: 100%;
        }
      }

      & > div > div {
        min-width: 280px;
      }

      &:first-child {
        & > div > div {
          margin-left: auto;
          margin-right: unset;
        }

        .slick-slide {
          & > div {
            padding-left: 0;
          }
        }
      }

      &:last-child {
        & > div > div {
          margin-left: unset;
          margin-right: auto;
        }

        .slick-slide {
          & > div {
            padding-right: 0;
          }
        }
      }

      &:not(.slick-current) {
        opacity: 1;
      }
    }
  }

  // For chaging dots style
  .slick-prev:before,
  .slick-next:before {
    content: "" !important;
  }

  .slick-dots {
    font-size: 0;
    bottom: 0px;
    transform: translateY(100%);

    li {
      margin: 0 7.5px;

      &.slick-active button {
        background: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text };
      }

      @media (hover: hover) {
        &:hover button {
          background: ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text };
        }
      }
    }

    li button {
      background: transparent;
      border: 1px solid ${({ theme }) => theme.sectionTheme?.text || defaultSectionTheme.text};
      width: 13px;
      height: 13px;
      border-radius: 50%;
      transition: .4s;
      cursor: pointer;

      &:before {
        display: none;
      }
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-bottom: ${props => props.length >= MAX_SLIDES - 2  ? 15 : 0}px;

    & > .slick-slider {
      padding-bottom: ${props => props.length >= MAX_SLIDES - 2 ? 30 : 0}px;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    padding-bottom: ${props => props.length >= MAX_SLIDES ? 100 : 0}px;

    & > .slick-slider {
      padding-bottom: ${props => props.length > 2 ? 30 : 0}px;
    }

    .slick-list {
      .slick-slide {
        & > div {
          padding: 0 ${props => props.length > DESIGN_MAX_SLIDES ? 10 : 28}px;
        }
      }
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.xl} {
    & > .slick-slider {
      padding-bottom: 0px;
    }
  }
`

export const Arrow = styled(ArrowRight)`
  height: 16px;
  fill: ${({ theme }) => theme.sectionTheme?.navButton?.color};
  transition: .4s;
`

const ArrowContainer = styled.div`
  width: 48px;
  height: 48px;
  background: transparent;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.sectionTheme?.navButton?.borderColor};
  border-radius: 50%;
  opacity: 1;
  left: unset;
  bottom: -50px;
  top: unset;
  transform: translateY(100%);
  transition: .4s;

  &:not(.slick-disabled):hover {
    ${Arrow} {
      fill: ${({ theme }) => theme.sectionTheme?.navButton?.hoverColor};
    }
  }

  &.slick-disabled {
    cursor: default;

    ${Arrow} {
      fill: ${({ theme }) => theme.sectionTheme?.navButton?.borderColor};
    }
  }
`

const arrowDistanceFromContainer = 40

export const NextArrowContainer = styled(ArrowContainer)`
  right: ${arrowDistanceFromContainer}px;
`

export const PrevArrowContainer = styled(ArrowContainer)`
  right: ${70 + arrowDistanceFromContainer}px;

  ${Arrow}, & > svg {
    transform: scaleX(-1);
  }
`
